  body {
    font-family: 'Kanit', sans-serif;
  }

 .hidden {
    display: none;
  }
  
  .shown {
    display: visible;
    transition: all .5s ease-out;
    opacity: 1;
    color: red;
  }

  .selected-label input:checked ~ .selected-content {
    border: solid 2px #219653;
  }
  
  
  .select-product {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    gap: 20px;
  }

  .select-product .card {
    cursor: pointer;
    position: relative;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    width: 230px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .select-product .card-body {
    padding: 16px 0;
    position: relative;
  }

  .select-product .card img {
  width: 100%;
  height: 230px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .p-text {
    padding: 0 16px 8px 16px;
    font-weight: 700;
  }

  .selected-content small,
  .product-text small {
    color: #EB5757;
    font-weight: 600;
}

  .add-basket-btn {
    color: #fff;
    background: #219653;
    width: 100%;
    bottom: 0;
    position: absolute;
    margin-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
  }
  
  //แก้ไขปุ่ม add-button
  .input-number {
    border: none; 
    outline: none;
    width: 150px;
    height: 30px;
    border-radius: 5px;
    background: #F2F2F2;
    color: #0a0a0a;
    vertical-align: middle;
  }
  .number-increment,
  .number-decrement {
    border-radius: 5px; 
    outline: none; 
    border: none; 
    width: 30px; 
    height: 30px;
    background: #BDBDBD;
    color: #0a0a0a;
    text-align: center;
    vertical-align: middle;
    text-align: left !important;
  }
//////////////////////////
  
  .basket-product {
    padding: 16px !important;
    position: relative;
    display: flex;
  }
  .delete-btn {
    position: absolute;
    right: 16px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    width: 31px;
    height: 23px;
    background: #FE4E4E;
    border: 1px solid #FE4E4E;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  .delete-btn:hover {
    background: #fff;
    color: #000;
    border: 1px solid #FE4E4E;
  }
  
  // Sale Price
    .old-price {
      font-size: 0.8rem;
      text-decoration: line-through;
      color: #828282;
  }
  .sale-price {
      color: #d0011b;
      font-size: 1.4rem;
      font-weight: 700;
  }
  .sale-text span {
      background-color: #d0011b;
      margin-left: 8px;
      font-size: .75rem;
      color: #fff;
      border-radius: 5px;
      padding: 0px 5px;
      font-weight: 400;
      line-height: 1;
      white-space: nowrap;
  }

  
  @media only screen and (max-width: 991px) {
    .select-product {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }
    .select-product .card {
        width: 200px;
    }
    .select-product .card img {
    height: 200px;
    }
    .input-number-group .input-number {
        width: 120px;
    }
  }

  @media only screen and (max-width: 767px) {
    .select-product {
        grid-template-columns: repeat(2, 1fr);
    }
    .select-product .card {
        width: 200px;
    }
    .select-product .card img {
    height: 200px;
    }
    .input-number-group .input-number {
        width: 120px;
    }
  }

  @media only screen and (max-width: 575px) {
    .select-product .card-body {
        padding: 10px 0;
    }
    
  }

  @media only screen and (max-width: 479px) {
    .select-product .card {
        width: 160px !important;
    }
    .select-product .card img {
    height: 160px;
    }
    .input-number-group .input-number {
    width: 80px;
    }

    // Sale Price
    .sale-text span {
      margin-left: 6px;
      font-size: .65rem;
    }
    .sale-price{
        font-size: 1.2rem;
    }

  }

  @media only screen and (max-width: 375px) {
    .select-product .card {
        width: 150px !important;
    }
    .select-product .card img {
    height: 150px;
    }
    .input-number-group .input-number {
    width: 70px;
    }
  }

  @media only screen and (max-width: 320px) {
    .select-product {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
    }
    .select-product .card {
        width: 140px !important;
    }
    .select-product .card img {
    height: 140px;
    }
    .input-number-group .input-number {
    width: 60px;
    }
  }

  @media only screen and (max-width: 280px) {
    .select-product {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
    }
    .select-product .card {
        width: 100% !important;
    }
    .input-number-group .input-number {
    width: 150px;
    }
  }


  




// ---------------------- mini website -------------------------//
// fix navbar

.menu-navbar-top .navbar a:hover{
  color:var(--mainColor);
}

.name-brand,
.name-brand0,
.top-menu a {
  &:hover {
    background: unset !important;
  }
}

@media only screen and (max-width: 767px) {
.name-brand {
  max-width: 70%;
  margin: 0 auto;
}
.name-brand0 {
  max-width: 70%;
  margin-left: 15px;
}
.status-btn {
  position: absolute;
  right: 15px;
}
}


// Sidebar mini website

.offcanvas-menu {
  position: absolute;
  top: 15px;
  > label {
    cursor: pointer;
    transition: 0.3s ease;
    z-index: 9999;
  }
  label {
    span,
    span::before,
    span::after {
      transition: background 0.3s, transform 0.3s;
    }
    span,
    span::before,
    span::after {
      content: "";
      position: absolute;
      height: 5px;
      width: 40px;
      background: #fff;
    }
    span::before {
      transform: translateY(-10px);
    }
    span::after {
      transform: translateY(10px);
    }
  }
  nav {
    position: fixed;
    height: 100%;
    width: 280px;
    left: -280px;
    top: 0;
    overflow: hidden;
    transition: 0.3s ease;
    z-index: 9999999;
    ul {
      padding: 15px 0;
      li {
        list-style: none;
        line-height: 40px;
        a {
          display: block;
          padding: 10px;
          text-decoration: none;
        }
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        margin: 8px 15px;
        width: 30px;
        height: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease;
        span,
        span:before,
        span:after {
          background: #fff;
        }
      }
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      ~ label {
        opacity: 0;
        pointer-events: none;
      }
      ~ nav {
        left: 0;
      }

      ~ nav label span {
        background: transparent;
      }
      ~ nav label span:before {
        transform: rotate(-45deg);
      }
      ~ nav label span:after {
        transform: rotate(45deg);
      }
      ~ nav label span:before,
      ~ nav label span:after {
        transition-delay: 0.2s;
      }
    }
  }
}
